const STORYBLOK_API_KEY = require('./src/storyblok-api-key');


module.exports = {
  siteMetadata: {
    title: 'Sertus',
    sharingImage: '/sharing.png',
    siteUrl: 'https://sertus.uk',
  },
  plugins: [
    {
      resolve: 'gatsby-source-storyblok',
      options: {
        accessToken: STORYBLOK_API_KEY,
        version: process.env.NODE_ENV === 'development' ? 'draft' : process.env.PULL_REQUEST ? 'draft' : 'published',
        homeSlug: 'home',
        resolveRelations: [
          `teamMembersData.data`,
          `teamMembers.teamMembersData`,
          `teamCarousel.teamMembers`,
          `productsGrid.data`,
          `caseStudiesBrowser.featuredProjects`,
          `productFeaturedCaseStudies.caseStudies`,
          `product.relatedProducts`,
          `product.featureTable`,
          `caseStudy.location`,
          `caseStudy.propertyType`,
          `download.categories`,
          `blogPost.category`,
          `product.category`,
          `navigation_item.dropdown`,
          `caseStudyRichLink.caseStudy`,
          `relatedProductsCarousel.products`,
          `blogPost.author`,
          `videoResourceItem.videoCategory`,
          `mobileMenuItem.submenu`,
          `caseStudyItem.link`,
          `blogRelatedPost.link`,
          `productSelectorItem.product`
        ],
      },
    },
    {
     resolve: "gatsby-plugin-eslint",
     options: {
       stages: ["develop"],
       extensions: ["js", "jsx", "ts", "tsx"],
       exclude: ["node_modules", "bower_components", ".cache", "public"]
      },
    },
    'gatsby-plugin-react-helmet',
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/images`,
      },
    },
    `gatsby-plugin-image`,
    `gatsby-transformer-sharp`,
    {
      resolve: `gatsby-plugin-sharp`,
      options: {
        useMozJpeg: false,
        stripMetadata: true,
        defaultQuality: 91,
      },
    },
    {
      resolve: 'gatsby-plugin-react-svg',
      options: {
        rule: {
          include: /^((?!\.url-loader).)*\.svg$/,
        },
      },
    },
    {
      resolve: `gatsby-plugin-styled-components`,
      options: {
        displayName: true,
        fileName: false,
      },
    },
    {
      resolve: `gatsby-plugin-portal`,
      options: {
        key: 'modal-root',
        id: 'modal-root',
      },
    },
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `Sertus`,
        short_name: `Sertus`,
        start_url: `/`,
        background_color: `#ffffff`,
        theme_color: `#363636`,
        display: `standalone`,
        icon: `src/images/favicon.png`,
      },
    },
    {
      resolve: `gatsby-plugin-google-fonts`,
      options: {
        fonts: ['Muli:400,400i,500,600,700,700i'], //eslint-disable-line
        display: 'swap',
      },
    },
    `gatsby-plugin-sitemap`,
    `gatsby-plugin-remove-serviceworker`,
    {
      resolve: `gatsby-plugin-fullstory`,
      options: {
        fs_org: `XEPCG`,
      },
    },
    {
      resolve: `gatsby-plugin-google-analytics`,
      options: {
        trackingId: 'UA-177232156-1',
      },
    },
  ],
};
